// src/components/layout/Footer.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as BIcon from 'react-bootstrap-icons';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import FadeLoader from 'react-spinners/FadeLoader';
import { useSnackbar } from 'notistack';

import { sendEmail, checkPrivateEmail, fadeIn, fadeOut } from 'utils/global';

const Footer = () => {
  const routesForNewBox = ['/pricing'];

  const location = useLocation();
  const isNewBox = routesForNewBox.includes(location.pathname);

  useEffect(() => {
    const handleScroll = () => {
      const toTop = document.querySelector('#return-to-top');

      if (window.scrollY > 250) {
        if (toTop.style.opacity == '' || parseFloat(toTop.style.opacity) <= 0)
          fadeIn(toTop, 200);
      } else {
        if (parseFloat(toTop.style.opacity) >= 1) fadeOut(toTop, 200);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="container-fluid">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            {!isNewBox ? (
              <div className="box">
                <div className="d-flex">
                  <h3>
                    Request a Live Demo or <br />
                    Book Directly Here
                    <a
                      href="/calendly"
                      target="_blank"
                      className="ms-3 inline-block"
                    >
                      <div className="flex items-center">
                        <BIcon.Calendar2Date className="me-2 !text-black" />{' '}
                        Calendly
                      </div>
                    </a>
                  </h3>
                </div>
                <EmailForm />
              </div>
            ) : (
              <div className="c-flex neflex">
                <div className="box newbox">
                  <div className="d-flex">
                    <h3>
                      Request a Live Demo or <br />
                      Book Directly Here
                      <a href="/calendly" target="_blank">
                        <i className="bi bi-calendar-date"></i> Calendly
                      </a>
                    </h3>
                  </div>
                  <EmailForm />
                </div>
                <div className="box newbox sbox">
                  <div className="d-flex">
                    <h3>Team Up with Us</h3>
                  </div>
                  <div className="d-flex">
                    <button>Become a partner</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12">
          <div className="lg:col-span-3">
            <a title="" href="/">
              <img
                title=""
                src={require('../../assets/images/flogo.png')}
                className="img-fluid logo"
                alt=""
              />
            </a>
            <p>Dark Web Threat Intelligence made in Switzerland</p>

            <div className="links">
              <a title="" href="/privacy-policy">
                Privacy Policy
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a title="" href="/imprint">
                Imprint
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a title="" href="/tos">
                ToS
              </a>
              {/* &nbsp;&nbsp;|&nbsp;&nbsp;
              <a title="" href="https://ch.trustpilot.com/review/kaduu.io">
                Rate us Trustpilot
              </a> */}
            </div>
          </div>

          <div className="lg:col-span-3 fborder">
            <h5>Navigation</h5>
            <ul>
              <li>
                <a title="" href="/">
                  Home
                </a>
              </li>
              <li>
                <a title="" href="/about-us">
                  About Us
                </a>
              </li>
              <li>
                <a title="" href="/pricing">
                  Pricing
                </a>
              </li>
              <li>
                <a title="" href="/about-us#contact-form">
                  Contact
                </a>
              </li>
              <li>
                <a title="" href="/solution/darknet-monitoring-mssp">
                  Partner
                </a>
              </li>
            </ul>
          </div>
          <div className="lg:col-span-3 fborder">
            <h5>Resources</h5>
            <ul>
              <li>
                <a title="" href="/knowledge">
                  News
                </a>
              </li>
              <li>
                <a title="" href="/solution#faq">
                  Q&A
                </a>
              </li>
              <li>
                <a title="" href="https://wiki.kaduu.ch/doku/doku.php">
                  Wiki
                </a>
              </li>
              <li>
                <a
                  title=""
                  href="https://darknetsearch.com/docs/Flyer2.pdf"
                >
                  Whitepaper
                </a>
              </li>
            </ul>
          </div>
          <div className="lg:col-span-2 fborder">
            <h5>Country Links</h5>
            <ul>
              <li>
                <a title="" href="/spain">
                  Spain
                </a>
              </li>
              <li>
                <a title="" href="/france">
                  France
                </a>
              </li>
              {/* <li>
                <a title="" href="#">
                  Germany
                </a>
              </li>
              <li>
                <a title="" href="#">
                  US/CA
                </a>
              </li> */}
            </ul>
          </div>
          <div className="lg:col-span-1 sfborder">
            <div className="flex flex-col justify-center d-flex justify-content-lg-center">
              <a
                title=""
                href="https://www.instagram.com/kaduu.io"
                target="_blank"
              >
                <BIcon.Instagram />
              </a>
              <a
                title=""
                href="https://www.youtube.com/@kaduudarkwebmonitoring"
                target="_blank"
              >
                <BIcon.Youtube />
              </a>
              <a title="" href="https://twitter.com/CtiKaduu" target="_blank">
                <BIcon.Twitter />
              </a>
              <a
                title=""
                href="https://www.linkedin.com/company/kaduu/"
                target="_blank"
              >
                <BIcon.Linkedin />
              </a>
              <a title="" href="mailto:info@kaduu.ch">
                <BIcon.EnvelopeFill />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copy">
        <p>
          Copyright &copy; <span id="year">{new Date().getFullYear()}</span>{' '}
          Kaduu. All Right Reserved "Dark Web Monitoring"
        </p>
      </div>
      <div className="top">
        <a
          id="return-to-top"
          title="Return to Top"
          className="inline"
          onClick={handleToTop}
        >
          <i>
            <BIcon.ArrowUp />
          </i>
        </a>
      </div>
    </footer>
  );
};

const EmailForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .min(2, 'Invalid name!')
          .max(50, 'Invalid name!')
          .required('Name is required'),
        email: Yup.string()
          .email('Invalid email address!')
          .required('Email address is required')
          .test(
            'check-private-email',
            'Only business email is valid.',
            checkPrivateEmail,
          ),
      })}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);

        const result = await sendEmail(
          values.email,
          {
            subject: 'Live demo request',
            message: `Title: Live Demo request\r\n\r\nUser Name: ${values.name}\r\nEmail: ${values.email}`,
          },
          enqueueSnackbar,
        );

        if (result) resetForm();
        setLoading(false);
      }}
    >
      {({ errors, touched }) => (
        <Form className="d-flex">
          <div className="w-full me-2">
            <Field
              name="name"
              type="text"
              placeholder="Name"
              disabled={loading}
            />
            {errors.name && touched.name ? (
              <div className="formik-error">{errors.name}</div>
            ) : null}
          </div>

          <div className="w-full me-2">
            <Field
              name="email"
              type="email"
              placeholder="Email address"
              disabled={loading}
            />
            {errors.email && touched.email ? (
              <div className="formik-error">{errors.email}</div>
            ) : null}
          </div>

          <div className="w-full">
            <button
              type="submit"
              className={loading ? 'loading' : ''}
              disabled={loading}
            >
              <div className="flex items-center justify-center">
                <span>Live Demo</span>
                <div
                  className="formik-loading ms-6 me-[-12px]"
                  style={{ display: loading ? 'inline' : 'none' }}
                >
                  <FadeLoader
                    height={8}
                    margin={-8}
                    radius={3}
                    width={3}
                    cssOverride={{ top: 19 }}
                  />
                </div>
              </div>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Footer;
