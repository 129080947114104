// src/components/layout/BasicLayout.js
import React from 'react';

const BasicLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex items-center justify-center w-full bg-gray-100">
      {children}
    </div>
  );
};

export default BasicLayout;
