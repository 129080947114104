import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  apiCallCount: 0,
  isCaptchaRequired: false,
  captchaVerified: false,
};

const rateLimitSlice = createSlice({
  name: 'rateLimit',
  initialState,
  reducers: {
    setAPICallCount: (state, action) => {
      state.apiCallCount = action.payload;
    },
    setIsCaptchaRequired: (state, action) => {
      state.isCaptchaRequired = action.payload;
    },
    setCaptchaVerified: (state, action) => {
      state.captchaVerified = action.payload;
    },
  },
});

export const { setAPICallCount, setIsCaptchaRequired, setCaptchaVerified } =
  rateLimitSlice.actions;

export default rateLimitSlice.reducer;
