// src/components/layout/index.js
import React, { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

import { trackPageView } from 'utils/analytics';

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow p-0">
        <Outlet /> {/* Renders the child route components */}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
