// src/components/common/LoadingSpinner.js
import React from 'react';
import FadeLoader from 'react-spinners/FadeLoader';

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen bg-[#f8cf3a]">
    <FadeLoader />
  </div>
);

export default LoadingSpinner;
